/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsMapMarkerContainer{
  overflow: hidden;
  position: relative;

  &:hover {

    .CmsMapMarkerOverlay {
      display: block;
      z-index: 10000;
    }
  }
}

.CmsMapMarker {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.CmsMapMarkerOverlay {
  background: $white;
  border-radius: 5px;
  bottom: 42px;
  box-shadow: 0 0 8px rgba(33,34,36,.24);
  left: -16px;
  min-width: 260px;
  padding: 20px;
  z-index: 100;

  &:after {
    background: $white;
    bottom: -6px;
    content: '';
    display: block;
    height: 17px;
    position: absolute;
    transform: rotate(45deg) skew(14deg, 14deg);
    width: 17px;
  }

  h4 {
    font-family: $fontFamily;
    font-size: $fontSize-lm;
    font-weight: $font-weight-s;
    padding-bottom: 15px;
  }

  p {
    font-family: $fontFamily;
    font-size: $fontSize-sm;
    padding-bottom: 5px;
  }
}

:global {

  .gm-ui-hover-effect {
    display: none !important;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CmsMapMarkerOverlay {
    left: -85px;

    &:after {
      left: 89px;
    }
  }
}
